import {Flex, Heading} from "@aws-amplify/ui-react";
import LandingScreenTeamMember from "./team-member";

export default function LandingScreenTeam() {
    return (<Flex direction={'column'} paddingTop={'50px'} paddingBottom={'100px'}>
            <Heading
                level={1}
                color={'#ff1616'}
                fontFamily={'muli, sans-serif'}
                textAlign={'center'}
            >
                Meet the Team
            </Heading>
            <Flex
                justifyContent={'center'}
                alignItems={'flex-start'}
                wrap={'wrap'}
            >
                <LandingScreenTeamMember
                    photo={'team-carolyn.jpg'}
                    name={'Carolyn Sloan'}
                    role={'Co-Founder & CEO'}
                    description={["MOM", "Veteran Educator of 25 years", "Award-winning Children's Book Author, Songwriter and Children's Media Producer",]}
                />

                <LandingScreenTeamMember
                    photo={'team-nathaniel.png'}
                    name={'Nathaniel Fairfield'}
                    role={'Co-Founder & CTO'}
                    description={["DAD", "Software Engineer", "Roboticist", "ML Expert", "Teacher"]}
                />

                <LandingScreenTeamMember
                    photo={'team-dory.png'}
                    name={'Dory Weiss'}
                    role={'Literacy and Education Specialist'}
                    description={["MOM", "M.A. Reading and Literacy", "Curriculum Designer", "Reading and Writing Coach",]}
                />
            </Flex>
        </Flex>)
}